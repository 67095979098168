import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import useSplit from 'hooks/useSplit';
import Container from 'components/Container';
import ActivityTag from 'components/common/tags/ActivityTag';
import { ActivityType, CoachSessionStyle, useSelfQuery } from 'graphql/types';
import SessionTag from 'components/common/tags/SessionTag';
import NewTextField from 'components/common/fields/NewTextField';
import {
  faAlien8bit,
  faFaceAwesome,
  faFaceCowboyHat,
} from '@fortawesome/pro-regular-svg-icons';
import Password from 'components/common/fields/Password';
import { Button } from 'components/common/Button';
import useLocalStorage from 'hooks/useLocalStorage';
import { Checkbox } from '@mui/material';

const ItemContainer = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.alias.mainBackground,
  padding: theme.baseUnit * 2,
  marginBottom: theme.baseUnit * 2,
  borderRadius: theme.baseUnit,
}));

const FixedWidthNewTextField = styled(NewTextField)(() => ({
  width: 'clamp(300px, 30%, 400px)',
}));
const FixedWidthPassword = styled(Password)(() => ({
  width: 'clamp(300px, 30%, 400px)',
}));

const resetBanners = () => {
  localStorage.removeItem('dismissed_banners');
  window.location.reload();
};

const Developer = () => {
  const { flags } = useSplit();
  const navigate = useNavigate();
  const [labelTextValue, setLabelTextValue] = useState('');
  const [withIconsValue, setWithIconsValue] = useState('');
  const [invalidValue, setInvalidValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const { data } = useSelfQuery();
  const selfId = data?.self?.id;
  const [eventListEnabled, setEventListEnabled] = useLocalStorage<boolean>(
    `${selfId}-toggle-trackingEventLog`,
    false,
  );

  const showDeveloperMenu = flags['show-developer-menu'];

  const toggleEventList = () => {
    setEventListEnabled(!eventListEnabled);
  };

  if (!showDeveloperMenu) {
    navigate('/');
    return null;
  }
  return (
    <main>
      <Container>
        <ItemContainer>
          <Label>Activity Tags</Label>
          <Row>
            <ActivityTag type={ActivityType.Virtual} />
            <ActivityTag type={ActivityType.InPerson} />
            <ActivityTag type={ActivityType.Encore} />
          </Row>
        </ItemContainer>
        <ItemContainer>
          <Label>Coach Session Tags</Label>
          <Row>
            <SessionTag type={CoachSessionStyle.Virtual} />
            <SessionTag type={CoachSessionStyle.InPerson} />
          </Row>
        </ItemContainer>
        <ItemContainer>
          <Label>New Text Field</Label>
          <Row>
            <FixedWidthNewTextField
              label="Label Text"
              helperText="Helper Text"
              value={labelTextValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLabelTextValue(e.target.value)
              }
            />
            <FixedWidthNewTextField
              label="With Icons"
              iconLeft={faFaceAwesome}
              iconRight={faFaceCowboyHat}
              value={withIconsValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setWithIconsValue(e.target.value)
              }
            />
            <FixedWidthNewTextField
              label="Invalid"
              isInvalid
              helperText="Invalid Input"
              value={invalidValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setInvalidValue(e.target.value)
              }
            />
            <FixedWidthNewTextField
              label="Disabled Input"
              iconLeft={faAlien8bit}
              isDisabled
              helperText="With helper text"
            />
            <FixedWidthNewTextField
              label="Invalid and Disabled"
              isInvalid
              helperText="Invalid Input"
              isDisabled
            />
          </Row>
        </ItemContainer>
        <ItemContainer>
          <Label>New Password</Label>
          <Row>
            <FixedWidthPassword
              label="Password"
              value={passwordValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPasswordValue(e.target.value)
              }
            />
          </Row>
        </ItemContainer>
        <ItemContainer>
          <Label>Reset Banners</Label>
          <Row>
            <Button onClick={resetBanners}>Reset All</Button>
          </Row>
        </ItemContainer>
        {
          // We want to hide this in production
          process.env.REACT_APP_ENV_LEVEL === 'dev' && (
            <ItemContainer>
              <Label>Show Tracking Event List</Label>
              <Row>
                <Checkbox
                  onClick={toggleEventList}
                  checked={!!eventListEnabled}
                />
              </Row>
            </ItemContainer>
          )
        }
      </Container>
    </main>
  );
};

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  marginBottom: theme.baseUnit,
  gap: theme.baseUnit,
  flexFlow: 'wrap',
}));

const Label = styled('div')(({ theme }) => ({
  ...theme.typography.textVariant.title.xs,
  color: theme.colors.alias.textSecondary,
  marginBottom: theme.baseUnit * 2,
}));

export default Developer;

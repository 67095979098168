import React from 'react';
import { usePlaybookTheme } from 'playbook';
import { ButtonProps, Button as MUIButton } from '@mui/material';

export const Button = ({ disabled, style, ...rest }: ButtonProps) => {
  const theme = usePlaybookTheme();
  return (
    <MUIButton
      {...rest}
      variant="contained"
      disabled={disabled}
      style={{
        ...theme.typography.textVariant.button.m,
        minHeight: theme.baseUnit * 7,
        color: disabled
          ? theme.colors.alias.textDisabled
          : theme.colors.alias.textContrast,
        padding: theme.baseUnit * 2.5,
        borderRadius: theme.baseUnit * 4,
        backgroundColor: disabled
          ? theme.colors.alias.primaryDisabled
          : theme.colors.alias.primary,
        ...style,
      }}
    />
  );
};

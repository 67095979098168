import { useCallback } from 'react';
import { sha1 } from 'crypto-hash';
import { v4 as uuid } from 'uuid';
import { MPTrack } from 'types/mixpanel';
import logger from 'utils/logger';
import { useMixpanel as useMixpanelBrowser } from '../utils/mixpanel';
import { useTrackingEventListContext } from '../providers/TrackingEventListProvider';
import { useMixpanelHookQuery } from '../graphql/types';
import useSplit from './useSplit';

const useMixpanel = (): MPTrack => {
  const { data, loading } = useMixpanelHookQuery({
    fetchPolicy: 'cache-first',
  });
  const { track: splitTrack } = useSplit();
  const { pushEvent } = useTrackingEventListContext();

  const coachId = data?.self?.fitCoach?.id;
  const princeUserId = data?.self?.id;
  // This returns a promise that probably should be awaited
  // Is this deprecated code or is it still in use?
  const heartUserId = data?.self?.email
    ? sha1(data?.self?.email).toString()
    : undefined;

  const mp = useMixpanelBrowser();

  return useCallback(
    async (name, passedInCfg): Promise<void> => {
      if (loading) {
        return;
      }
      const cfg = { coach_id: coachId, ...passedInCfg };
      logger.debug('TRACK', name, cfg);

      const env = process.env.NODE_ENV ?? 'development';
      const genericData = {
        application_name: 'Exos Fit',
        sp_user_id: princeUserId,
        timestamp: new Date().toISOString(),
      };

      // do not send Mixpanel events in development
      if (env !== 'development') {
        if (mp) {
          mp.track(name, {
            user_id: heartUserId,
            ...genericData,
            ...cfg,
          });
        }

        // track to Split.io
        let eventName = ''; // handle type narrowing
        if (typeof cfg.event_name === 'string') {
          eventName = cfg.event_name;
        } else if (typeof cfg.screen_name === 'string') {
          eventName = `view_${cfg.screen_name}`;
        }

        if (eventName !== '') {
          // will avoid tracking if not have event_name or screen_name
          await splitTrack(eventName, undefined, {
            ...cfg,
          } as SplitIO.Properties);
        }
      }

      if (process.env.REACT_APP_ENV_LEVEL === 'dev') {
        pushEvent(uuid(), name, { genericData, specificData: cfg });
      }
    },
    [loading, coachId, mp, heartUserId, princeUserId, splitTrack, pushEvent],
  );
};
export default useMixpanel;

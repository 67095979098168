import React from 'react';
import { AuthProvider } from '@teamexos/fit-shared';

import { SnackbarContextProvider } from 'contexts/snackbarContext';

import useMixpanel from 'hooks/useMixpanel';
import { MixpanelProvider } from '../utils/mixpanel';
import PrinceSDKProvider from '../contexts/princeSDKContext';
import ThemeProviders from './ThemeProviders';
import InactivityProvider from './InactivityProvider';
import TrackingEventListProvider from './TrackingEventListProvider';
import { FirebaseProvider } from '../contexts/firebaseContext';

const sharedPlatformDomain = process.env.REACT_APP_PRINCE_GQL_URL;

if (!sharedPlatformDomain) {
  throw new Error('REACT_APP_PRINCE_GQL_URL is not defined');
}

const Providers: React.FC<React.PropsWithChildren> = ({ children }) => (
  <AuthProvider
    sharedPlatformDomain={sharedPlatformDomain}
    trackHook={useMixpanel}
    localSignOut={async () => {}}
  >
    <FirebaseProvider>
      <PrinceSDKProvider>
        <ThemeProviders>
          <SnackbarContextProvider>
            <MixpanelProvider token={process.env.REACT_APP_MIXPANEL_TOKEN}>
              <InactivityProvider>
                <TrackingEventListProvider>
                  {children}
                </TrackingEventListProvider>
              </InactivityProvider>
            </MixpanelProvider>
          </SnackbarContextProvider>
        </ThemeProviders>
      </PrinceSDKProvider>
    </FirebaseProvider>
  </AuthProvider>
);

export default Providers;

import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  faAngleDown,
  faAngleUp,
  faGripLines,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTimeFormatter, ZonedDateTime } from '@js-joda/core';
import { Locale } from '@js-joda/locale_en-us';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  Collapse,
  IconButton,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import styled from '@emotion/styled';
import { useAuth } from '@teamexos/fit-shared';
import noop from 'lodash/noop';
import { Resizable } from 're-resizable';
import { usePlaybookTheme } from 'playbook';
import { useSelfQuery } from '../graphql/types';
import useLocalStorage from '../hooks/useLocalStorage';

const EventTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.body.s,
  letterSpacing: '0.5px',
}));

const EventDetailTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.textVariant.label.m,
  display: 'inline-flex',
  justifySelf: 'center',
}));

const StyledPreText = styled('pre')(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.xs,
}));

const hourMinuteSecondFormat = DateTimeFormatter.ofPattern(
  'hh:mm:ss',
).withLocale(Locale.US);

interface ITrackingEventListContext {
  pushEvent: (id: string, eventName: string, eventData: any) => void;
}

const Context = createContext<ITrackingEventListContext>({
  pushEvent: noop,
});

const TrackingEventListProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const { isLoggedIn, isLoading: isAuthLoading } = useAuth();
  const { colors } = usePlaybookTheme();
  const { data } = useSelfQuery({
    skip: !isLoggedIn || isAuthLoading,
  });
  const selfId = data?.self?.id;
  const [enabled] = useLocalStorage<boolean>(
    `${selfId}-toggle-trackingEventLog`,
    false,
  );

  const [events, setEvents] = useState<
    { id: string; eventName: string; eventData: any; time: ZonedDateTime }[]
  >([]);
  const [open, setOpen] = useState(false);

  const context = useMemo(
    () => ({
      pushEvent: (id: string, eventName: string, eventData: any) => {
        if (!enabled) {
          return;
        }
        setEvents((prev) => [
          { id, eventName, eventData, time: ZonedDateTime.now() },
          ...prev,
        ]);
      },
    }),
    [enabled],
  );

  if (!enabled) {
    return <Context.Provider value={context}>{children}</Context.Provider>;
  }

  return (
    <Context.Provider value={context}>
      {children}
      {enabled && (
        <Card
          sx={{
            position: 'fixed',
            bottom: '0',
            left: '16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            zIndex: 9999, // This is required for the list to appear above content pages
            minWidth: '300px',
            borderRadius: '8px 8px 0 0',
            boxShadow: '1px 3px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <ListItemButton
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              gap: 2,
            }}
            onClick={() => setOpen((prev) => !prev)}
          >
            <ListItemText primary="Tracking Events" />

            {open ? (
              <FontAwesomeIcon icon={faAngleDown} />
            ) : (
              <FontAwesomeIcon icon={faAngleUp} />
            )}
          </ListItemButton>

          <Collapse
            in={open}
            sx={{
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                overflowY: 'auto',
                paddingTop: 1,
              }}
            >
              <Resizable
                maxHeight="75vh"
                enable={{
                  top: true,
                }}
                handleComponent={{
                  top: (
                    <Box
                      sx={{
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faGripLines}
                        style={{ position: 'absolute' }}
                      />
                    </Box>
                  ),
                }}
              >
                <Box>
                  {events.map((event) => (
                    <React.Fragment key={event.id}>
                      <Accordion
                        sx={{
                          margin: 0,
                          width: '100%',
                          minHeight: 'unset',
                          boxShadow: 'none',
                          '&.MuiAccordionSummary-content, &.Mui, &.Mui-expanded':
                            {
                              margin: 0,
                            },
                        }}
                      >
                        <AccordionSummary
                          sx={{
                            minHeight: 'unset',
                            '&.Mui-expanded': {
                              minHeight: 'unset',
                            },
                            '& .MuiAccordionSummary-content': {
                              margin: '6px 0',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            },
                            '& .MuiAccordionSummary-content.Mui-expanded': {
                              margin: '6px 0',
                            },
                          }}
                        >
                          <EventTitle variant="h4">
                            {event.time.format(hourMinuteSecondFormat)}{' '}
                            {event.eventName}
                          </EventTitle>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              setEvents((prev) =>
                                prev.filter(
                                  (prevEvent) => prevEvent.id !== event.id,
                                ),
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTimes}
                              fontSize={16}
                              // color={BLACK2}
                            />
                          </IconButton>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            padding: '8px',
                            background: colors.alias.contrastHover,
                          }}
                        >
                          <details>
                            <summary style={{ cursor: 'pointer' }}>
                              <EventDetailTitle variant="h5">
                                Generic
                              </EventDetailTitle>
                            </summary>
                            <StyledPreText>
                              {JSON.stringify(
                                event.eventData.genericData,
                                null,
                                2,
                              )}
                            </StyledPreText>
                          </details>
                          <details open>
                            <summary style={{ cursor: 'pointer' }}>
                              <EventDetailTitle variant="h5">
                                Specific
                              </EventDetailTitle>
                            </summary>
                            <StyledPreText>
                              {JSON.stringify(
                                event.eventData.specificData,
                                null,
                                2,
                              )}
                            </StyledPreText>
                          </details>
                        </AccordionDetails>
                      </Accordion>
                    </React.Fragment>
                  ))}
                </Box>
              </Resizable>
            </Box>
            <Box
              sx={{ borderTop: `1px solid ${colors.alias.mainSurfaceDivider}` }}
            >
              <Button
                fullWidth
                variant="text"
                color="primary"
                size="small"
                onClick={() => setEvents([])}
              >
                Clear
              </Button>
            </Box>
          </Collapse>
        </Card>
      )}
    </Context.Provider>
  );
};

export const useTrackingEventListContext = () => useContext(Context);

export default TrackingEventListProvider;
